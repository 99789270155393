import React, { memo, useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import COLORS from "../../../../assets/colors";
import { AuthService } from "../../../../services/AuthService";
import { SkillService } from "../../../../services/SkillService";
import { UIStore } from "../../../../store/store_state";
import { FunSkillKey, GroupByKey } from "../../../utils";
import { H4, H6 } from "../../../Common/Typography";
import CompareColumn from "../PersonaCompare/CompareColumn";
import { SortString } from "../../../../helpers/SortString";
import ModalPopup from "../../../Common/ModalPopup";
import useFullLoader from "../../../../Hooks/useFullLoader";
import SkillUpdatePopup from "../../ArchitectSkills/SkillContent/SkillUpdatePopup";
import { LevelIdSet } from "../../../utils/LevelId";
import useAuth from "../../../../Hooks/useAuth";
import useRoles from "../../../../Hooks/useRoles";
import { isValidUser } from "../../../../helpers/CommonHelper";
import { UserType } from "../../../../enums/UserType";

export default memo((props) => {
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const { clientName } = useRoles();

  // console.log("User_Type", User_Type, typeof User_Type);

  const selectRoles = UIStore.useState((s) => s.selectRoles);
  const { setFullLoader } = useFullLoader();

  const [skillList, setSkillList] = useState({});
  const [skillArray, setSkillArray] = useState([]);
  const [skillTabs, setSkillTabs] = useState([]);
  const [selectRolesDummy, setSelectRolesDummy] = useState([]);

  const [skillDetail, setSkillDetail] = useState(null);
  const [skillProficiency, setSkillProficiency] = useState(null);
  const [skillPopup, setSkillPopup] = useState(false);

  const initOpen = null;
  let rowRefs = useRef([]);
  rowRefs.current = !!skillList
    ? Object.keys(skillList).map(
        (ref, index) => (rowRefs.current[index] = createRef())
      )
    : rowRefs;

  const [expanded, setExpanded] = useState("panel" + initOpen);

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  const getPersonaSkills = async (id, type) => {
    // Include clientId in the parameter for general users or if a clientId is provided in the props.
    const clientIdParam =
      (isValidUser(User_Type, UserType.GENERAL_USER) || props?.clientId) ? `?clientId=${clientName}` : "";

    try {
      if (type === "add") {
        const personaSkill = await AuthService.getSkillListV3(
          "/" + id + `/skills` + clientIdParam
        );
        const mergeWithPrev = SortString(
          [...skillArray, ...personaSkill.filter((el) => el.roleId === id)],
          "skillName"
        );
        const arrLength = selectRoles.filter((el) => !!el).length;
        setSkillArray(mergeWithPrev);
        assignedSkills(
          arrLength === 1
            ? mergeWithPrev.filter(
                (el) => !!el && !!el?.proficiencyLevel?.level
              )
            : mergeWithPrev
        );
      } else {
        const mergeWithPrev = [...skillArray].filter(
          (skill) => skill.roleId !== id
        );
        setSkillArray(mergeWithPrev);
        assignedSkills(mergeWithPrev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const assignedSkills = (list) => {
    const arrPersona = list.map((el) => {
      return {
        ...el,
        col: selectRoles
          .map((elem) =>
            list.find(
              (element) =>
                elem === element.roleId &&
                element.skillId === el.skillId &&
                elem !== ""
            )
          )
          .map((item, index, arr) =>
            (!!item &&
              arr.findIndex(
                (arrItem) =>
                  arrItem?.proficiencyLevel?.level ===
                  item?.proficiencyLevel?.level
              ) !== index) ||
            (!!item &&
              arr.findIndex(
                (arrItem) =>
                  arrItem?.proficiencyLevel?.level ===
                  item?.proficiencyLevel?.level
              ) === index &&
              (arr[index + 1]?.proficiencyLevel?.level ===
                item?.proficiencyLevel?.level ||
                arr[index + 2]?.proficiencyLevel?.level ===
                  item?.proficiencyLevel?.level ||
                arr[index + 3]?.proficiencyLevel?.level ===
                  item?.proficiencyLevel?.level))
              ? { sameLevel: true, ...item }
              : item
          ),
      };
    });

    var setObj = new Set(); // create key value pair from array of array
    var result = arrPersona
      .map((el) => ({ ...el, category: el?.skillCategory?.skillCategory }))
      .reduce((acc, item) => {
        if (!setObj.has(item.skillId)) {
          setObj.add(item.skillId, item);
          acc.push(item);
        }
        return acc;
      }, [])
      .filter(
        (el) =>
          !el.col
            .filter((col) => !!col)
            .every(
              (elem) =>
                elem?.proficiencyLevel?.level === "" &&
                selectRoles.filter((role) => !!role).length > 1
            )
      );

    // console.log("assignedSkills -> result", result);
    const makeGroup = GroupByKey(result, "category");
    setSkillList(makeGroup);

    setSkillTabs([
      FunSkillKey,
      ...Object.keys(makeGroup)
        .filter((el) => el !== FunSkillKey)
        .sort()
        .map((item) => item),
    ]);
    setSelectRolesDummy(selectRoles);
  };

  const handleSkillPopup = async (item) => {
    try {
      const resDetail = await SkillService.getSkillDetailV3(
        "/" + item.skillId,
        "/?includeProficiencies=true"
      );
      setSkillDetail(resDetail);
      const resProficiency = await SkillService.getSkillTypesArrayV3(item.skillId, "proficiencies");

      setSkillProficiency(SortString(resProficiency, "levelNumber"));
      setSkillPopup(true);
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  };

  const closePopup = () => setSkillPopup(false);

  useEffect(() => {
    setExpanded("panel" + initOpen);
    return () => {
      setExpanded(null);
    };
  }, [initOpen]);

  useEffect(() => {
    const perId = selectRoles.find((el) => !selectRolesDummy.includes(el));
    const removeId = [...selectRolesDummy].find(
      (el) => !selectRoles.some((elem) => elem === el)
    );
    // console.log(perId);
    if (!!perId && perId !== "") {
      getPersonaSkills(Number(perId), "add");
    } else {
      getPersonaSkills(Number(removeId), "remove");
    }
    setSelectRolesDummy(selectRoles);
    return () => {
      // setSkillList([]);
    };
  }, [selectRoles]);

  //   console.log(selectRoles, skillList, skillTabs);
  return (
    <AccordionList>
      {skillTabs.map((item, i) => (
        <Accordion
          square
          ref={rowRefs.current[i]}
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`panel${i}d-content`}
            id={`panel${i}d-header`}
            disabled={!skillList[item]?.length}
          >
            <H4>{item}</H4>
          </AccordionSummary>
          <AccordionDetails>
            {!!Object.keys(skillList).length && (
              <>
                {skillList[item]?.map((skill, index) => (
                  <CompareColumn key={index} row={true}>
                    <div className="skill-wrap">
                      <div className="skill-col">
                        <H6 onClick={() => handleSkillPopup(skill)}>
                          {skill.skillName}
                        </H6>
                      </div>
                    </div>
                    {skill.col?.map((persona, idx) => (
                      <div key={idx} className="profi-col">
                        {!!persona ? (
                          <div
                            className={
                              "col-box " +
                              (!!persona?.proficiencyLevel.level
                                ? "bg" +
                                  LevelIdSet(persona?.proficiencyLevel.level)
                                : "bg0") +
                              (!!persona?.sameLevel ? " green-box " : "") +
                              (!!persona.updated ? " active " : "")
                            }
                          >
                            {persona?.proficiencyLevel?.level}
                          </div>
                        ) : (
                          <>
                            {selectRoles.map((dummy, dummyID) =>
                              dummy !== "" && dummyID === idx ? (
                                <div className="col-box red-box"></div>
                              ) : null
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </CompareColumn>
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <ModalPopup
        open={skillPopup}
        maxWidth="lg"
        closePopup={closePopup}
        title={!!skillDetail && skillDetail.skillName}
      >
        <SkillUpdatePopup
          data={skillDetail}
          listProficiency={skillProficiency}
          closePopup={closePopup}
          withCilentID={User_Type !== 2}
          clientView
        />
      </ModalPopup>
    </AccordionList>
  );
});

const AccordionList = styled.div`
  .accodion-icon {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    height: 24px;
    width: 38px;
  }

  .skill-col,
  .profi-col {
    h6,
    .col-box {
      height: 40px;
      margin: 2px 0px;
    }
    h6 {
      display: flex;
      align-items: center;
    }
    .col-box {
      border: solid 1px ${COLORS.ASH_BORDER};
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 100%;
      &.red-box {
        background-color: ${COLORS.PRIMARY_WHITE};
      }
      &.green-box {
        /* border-color: ${COLORS.GREEN};
        border-width: 2px; */
      }
      &.bg0 {
        background-color: ${COLORS.BLUE_BG0};
      }
      &.bg1 {
        background-color: ${COLORS.BLUE_BG1};
      }
      &.bg2 {
        background-color: ${COLORS.BLUE_BG2};
      }
      &.bg3 {
        background-color: ${COLORS.BLUE_BG3};
      }
      &.bg4 {
        background-color: ${COLORS.BLUE_BG4};
      }
      &.bg5 {
        background-color: ${COLORS.BLUE_BG5};
        color: ${COLORS.PRIMARY_WHITE};
      }
      &.bg6 {
        background-color: ${COLORS.BLUE_BG6};
        color: ${COLORS.PRIMARY_WHITE};
      }
      &.bg7 {
        background-color: ${COLORS.BLUE_BG7};
        color: ${COLORS.PRIMARY_WHITE};
      }
      &.active {
        border: solid 2px ${COLORS.BLUE};
        box-shadow: 0 2px 4px 0 rgba(95, 130, 188, 0.26);
      }
    }
  }
`;

const Accordion = withStyles({
  root: {
    // borderBottom: "1px solid #ccc !important",
    boxShadow: "none",
    marginBottom: "0px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      height: 0,
    },
    "&$expanded": {
      margin: "auto",
    },
    "& .MuiAccordionSummary-root": {
      borderBottom: `solid 1px ${COLORS.ASH_BORDER}`,
      marginBottom: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    padding: `0px 28px`,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    "& h4": {
      paddingBottom: 0,
    },
  },
  expandIcon: {
    border: `solid 1px ${COLORS.ASH_BORDER}`,
    borderRadius: 8,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
    height: 24,
    marginRight: 0,
    width: 38,
    "&.Mui-expanded": {
      boxShadow: "none",
      opacity: 0.4,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: "0 28px 10px",
  },
}))(MuiAccordionDetails);
